
// Fonts
@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,600");

// Variables
@import "variables";

// Plugin
@import "_materialswitch";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// Custom
@import "_tabs";

.inline{
    display: inline-block;
}

table.table tbody tr td{
    vertical-align: middle;
}

input, textarea, button, a {outline: none !important; }

.form-control{
    box-shadow: none;
    &:focus{
        box-shadow: none;
    }
}

.panel-footer {
    .pagination {
        margin: 0;
        display: block;
    }
}