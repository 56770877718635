.material-switch > input[type="checkbox"] {
    display: none;
}

.material-switch > label {
    cursor: pointer;
    height: 0px;
    position: relative;
    width: 40px;
}

.material-switch > label::before {
    background: rgb(0, 0, 0);
    border-radius: 11px;
    content: '';
    height: 22px;
    margin-top: -10px;
    position:absolute;
    opacity: 0.3;
    transition: all 0.4s ease-in-out;
    width: 40px;
}
.material-switch > label::after {
    background: rgb(255, 255, 255);
    border-radius: 16px;
    content: '';
    height: 20px;
    left: 1px;
    margin-top: -10px;
    position: absolute;
    top: 1px;
    transition: all 0.3s ease-in-out;
    width: 20px;
}
.material-switch > input[type="checkbox"]:checked + label::before {
    background: inherit;
    opacity: 1;
}
.material-switch > input[type="checkbox"]:checked + label::after {
    left: 19px;
}