.headertab{
    margin-left: -15px;
    margin-right: -15px;
    margin-top: -15px;
    padding-top: 15px;
    background-color: #f5f8fa;
    h2{
        margin-left: 15px;
    }
}
.nav-tabs{
    border: none;
    li{
        border: none;
        border-radius: 0;
        &.active{
            a{
                border: none;
                border-bottom: solid 2px $laravel-border-color;
                &:hover{
                    border: none;
                    border-bottom: solid 2px $laravel-border-color;
                }
            }
        }
        a{
            border: none;
            border-bottom: solid 2px transparent;
            color: #555;
            &:hover{
                border: none;
                border-bottom: solid 2px $laravel-border-color;
                background-color: transparent;
            }
        }
    }
}